import PhotoSwipeLightbox from 'photoswipe/dist/photoswipe-lightbox.esm.js';
import PhotoSwipe from 'photoswipe/dist/photoswipe.esm.js';

const translations = {
	cs: {
		closeTitle: 'Zavřít',
		zoomTitle: 'Přiblížit',
		arrowPrevTitle: 'Předchozí',
		arrowNextTitle: 'Následující',
		errorMsg: 'Obrázek se nepodařilo načíst',
	},
};

export class PhotoAlbum {
	#galleries = new Map();

	constructor () {}

	init() {
		for (const [elem, pswp] of this.#galleries) {
			if (!document.documentElement.contains(elem)) {
				pswp.destroy();
				this.#galleries.delete(elem);
			}
		}

		for (const elem of document.querySelectorAll('.album')) {
			if (!this.#galleries.has(elem)) {
				this.#galleries.set(elem, this.#create(elem));
			}
		}
	}

	#create(elem) {
		const pswp = new PhotoSwipeLightbox({
			gallery: elem,
			children: 'a',
			pswpModule: PhotoSwipe,
			...(translations[document.documentElement.lang] || {}),
		});

		pswp.addFilter('domItemData', (itemData, element, linkEl) => {
			if (linkEl) {
				itemData.srcset = linkEl.dataset.srcset;
				itemData.w = Number(linkEl.dataset.width);
				itemData.h = Number(linkEl.dataset.height);
				itemData.thumbCropped = true;
			}

			return itemData;
		});

		pswp.init();
		return pswp;
	}
}
